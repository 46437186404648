export default {
    global: {
        projectType: {
            type3: '《新品上市前策》',
            type4: '《社交口碑营销》',
            type6: '《整合营销创意》'
        },
        params: {
            param1: '产品Slogan与Slogan诠释',
            param2: '传播口号与传播口号创意说明',
            param3: '传播主题Theme',
            param4: '核心创意概述',
        },
        successMsg: '恭喜你，成功完成！',
        loginFailed: '登录异常，请联系客服。',
        errorMsg: 'Oops，出现了错误。',
        InsufficientBalance: '额度不足，请充值',
        concurrentError:{
            title:'权限不足',
            content:'目前有任务在进行中，如您想同时让艾迪处理多个任务，欢迎联系商务升级为超级会员',
            ok:'我知道了'
        }
    },
    views: {
        LoginPage: {
            title: '欢迎来到',
            username: '请输入账号',
            password: '请输入密码',
            login: '登录',
            qrcode: {
                title: '在线客服'
            }
        },
        TemplateSelection: {
            pageDesc: '你的创意搭档',
            template1: {
                title: '02 新品上市前策',
                desc: '为新品上市推广，推导完成传播策略分析与传播口号创意。',
                dialog: {
                    suitableScene: '全新产品上市、创作产品传播口号、需要前端传播策略推导',
                    noIdea: {
                        output1: '人群洞察创意标签',
                        output2: '新品传播口号创意',
                    },
                    hasIdea: {
                        output1: '市场分析',
                        output2: '竞品分析',
                        output3: '产品卖点分析',
                        output4: '人群分析',
                        output5: '制胜传播策略',
                        output6: '新品传播定位',
                        output7: '新品传播口号'
                    }
                }
            },
            template2: {
                title: '03 社交口碑营销',
                desc: '为产品的社交平台推广，推导完成社交心智、传播主题与核心创意。',
                dialog: {
                    suitableScene: '产品/品牌/事件、社交平台推广、产出社交口碑心智',
                    noIdea: {
                        output1: '人群洞察',
                        output2: '传播主题与核心创意',
                    },
                    hasIdea: {
                        output1: '社交口碑心智',
                        output2: '定义差异化核心价值',
                        output3: '人群分析',
                        output4: '人群洞察创意标签',
                        output5: '制胜心智策略',
                        output6: '传播主题与核心创意',
                        output7: '创意文案词组',
                    },
                    notice: '注意：方案不含市场分析、竞品分析与产品卖点分析，若需要请选择“新品上市前策”。'
                }
            },
            template3: {
                title: '04 整合营销创意',
                desc: '为产品的社交平台推广，打造社交热点事件，完成整合营销创意方案。',
                dialog: {
                    suitableScene: '产品/品牌/事件、社交平台推广、产出整合营销全案创意',
                    noIdea: {
                        output1: '人群洞察',
                        output2: '传播主题与核心创意',
                        output3: 'Buzz Point 热点事件创意',
                    },
                    hasIdea: {
                        output1: '人群洞察',
                        output2: '传播主题与核心创意',
                        output3: 'Buzz Point 热点事件',
                        output4: 'Teaser 阶段创意与话题',
                        output5: 'Launch 阶段创意与话题',
                        output6: 'Sustain 阶段创意与话题',
                        output7: '扩散/ 转化/ 平台选择',
                        output8: 'KOL 破圈话题创意',
                        output9: 'KOL 量级推荐与话题创意',
                        output10: 'KOL 投放矩阵',
                    }
                }
            },
            template4: {
                title: '01 市场情报官',
                desc: '快速拟定市场策略、了解竞争品牌、洞察消费者、分析产品卖点、分析营销需求。',
                dialog: {
                    suitableScene: '快速拟定市场策略、了解竞争品牌、洞察消费者、分析产品卖点、分析营销需求。',
                    outputs: {
                        output1: '市场竞争情况报告',
                        output2: '竞品品牌对比报告',
                        output3: '本品优势卖点和消费者评价',
                        output4: '目标人群的产品洞察报告',
                        output5: '行业媒介投放情况报告',
                        output6: '竞品媒介投放情况报告',
                        output7: '竞品传播策略分析报告'
                    }
                }
            },
            modal: {
                suitableScene: '适合场景：',
                noIdea: '脑暴模式输出',
                hasIdea: '完整方案输出',
                output: '输出',
                ok: '我知道了'
            },
            recentProject: {
                title: '你最近的方案',
                mode: {
                    noIdea: '脑暴模式',
                    hasIdea: '完整方案模式',
                    research: '情报调研'
                }
            },
            announcement:{
                title:'ADGO新功能',
                content:'<div>1.疯狂创意模式：我们在头脑风暴功能下加入了“疯狂创意模式”，只需要打开“疯狂模式”按钮，你将在脑暴创意中获得比从前更具有脑洞的创意，当然你也可以关闭它来获取更符合本次任务逻辑的创意</div>' +
                    '<div>2.市场情报官板块：很高兴向您推出该功能。在该板块下，您只需要输入您想了解的任何关于产品，市场，人群等一系列的市场情报信息。相较于在Brief中的市场简报，您可以在“市场情报官”板块中获得更全面，更多信息维度，更深入的市场情报</div>',
                ok:'我知道了'
            }
        },
        ModeSelection: {
            button: {
                return: '返回',
                generate: '生成Brief',
                research: '开始调研'
            },
            pageDesc: '今天你想创造什么？',
            noIdea: '头脑风暴模式',
            hasIdea: '完整方案模式',
            giveWhat: {
                idea: "很多超棒的IDEA",
                plan: "超完整的思路和方案"
            },
            label1: '本次营销任务（必填）：',
            label2: '传播主题 Theme（选填，若不填写 ADGO 将为你想出来）：',
            label3: '核心创意概述（选填，若不填写 ADGO 将为你想出来）：',
            label4: '传播主题文案（选填，若不填写 ADGO 将为你想出来）：',
            label5: '传播主题创意概念说明（选填，若不填写 ADGO 将为你想出来）：',
            label6: '疯狂模式：',
            tooltip6: '打开它，你会获得许多异想天开的创意',
            placeholder1: '请告诉艾迪这次新品上市的营销任务的需求，可能包括但不限于以下信息：品牌名/ 产品或活动名/ 产品或活动的卖点描述/ 目标人群基础资料/ 主打市场/ 竞争品牌的产品或活动/ 竞争品牌的产品或活动的卖点描述。\n\n如需要参考，你也可以点击下方的范例。',
            placeholder2: '请输入已经完成的传播主题创意，可能包括但不限于：传播主题文案/ 传播主题创意说明。接下来ADGo将会基于你提供的传播主题，继续完成全部方案。\n\n如需要参考，你也可以点击下方的范例。',
            placeholder3: '请输入已经完成的主创意，可能包括但不限于：创意概念/ 创意手法/ 媒体创意/ 创意内容/ 等具体执行创意。接下来ADGo将会基于你提供的主创意描述，继续完成全部方案。\n\n如需要参考，你也可以点击下方的范例。',
            placeholder4: '请输入已经完成的传播主题文案，可能包括但不限于：传播主题文案/ Slogan。接下来ADGo将会基于你提供的传播主题文案，继续完成全部方案。\n\n如需要参考，你也可以点击下方的范例。',
            placeholder5: '请输入传播主题文案的创意概念说明，可能包括但不限于：创意概念/ 创意洞察/ 文案手法/ 创意来源/ 为何打动人/ 等文案创意的详细介绍。接下来ADGo将会基于你提供的传播主题创意，继续完成全部方案。\n\n如需要参考，你也可以点击下方的范例。',
            example: {
                title: '参考范例',
            },
            example1: {
                title: '范例一',
                brief: 'Under armour即将在2024年上新一款鞋slipspeed，它的核心卖点是鞋后跟可以折叠，折叠起来日常休闲可当拖鞋使用，鞋跟立起可以作为专业的训练鞋在运动中使用，品牌想借此新品上市，在推广产品的同时，突破品牌在消费者心中硬核专业的形象，拓展更多圈层人群，此鞋款受众18-30岁，主要竞品产品：Nike Metcon 9、Reebok Nano X4。',
                spreadTheme: '脚下解锁，生活无限可能',
                coreIdea: '此创意源自现代都市生活的多面性，结合SlipSpeed可折叠后跟的独特设计，创造出"解锁"这一富有想象力的概念。它不仅暗示了产品的变形功能，更寓意着解锁生活中的无限可能。这一主题迎合了年轻人追求效率与多元化的需求，引发他们对简化生活、提升品质的共鸣。同时，巧妙运用了当下流行的"解锁新姿势"社交热梗，激发用户的好奇心和参与欲，符合"新奇有趣"的社交动机。'
            },
            example2: {
                title: '范例二',
                brief: '雀巢阿拉比卡咖啡品种「Star 4」，强调低碳排放和供应链优化，特别吸引环保意识强烈和注重质量的咖啡爱好者，精准锁定中国市场中注重生活质量和环境永续的都市人士。这款咖啡不仅具有独特风味，还特别强调其对应气候变迁的创新性，适合日常饮用和特殊场合品尝。对标其他精品咖啡品牌，强调雀巢在环保创新、质量控制以及全球供应链优化上的优势。',
                spreadTheme: '「一杯咖啡，一份责任」',
                coreIdea: '在这个对环保意识日益重视的时代，雀巢Star 4不仅满足了顾客对咖啡口感的苛刻要求，更满足了他们对环保的道德期待。每一杯Star 4，都是对生活质量与环境保护双重承诺的展示，它不只是咖啡，更是一份对未来的责任。'
            },
            example3: {
                title: '范例一',
                brief: '全家便利商店的「开学季优惠」活动 活动内容： 全家便利商店在小红书上针对大学生举办了「开学季优惠」活动，发布各种折扣和优惠信息，同时在小红书上进行问答互动，解答学生的各种问题。 目标明确：精准锁定大学生群体，活动设计符合他们的需求和喜好。 平台互动：利用小红书的社交互动功能，增加用户参与度和品牌好感度。 实用性强：提供实际的折扣和优惠，直接提升门店销量。竞争品牌：7-ELEVEN、莱尔富、全家 FamilyMart 的开学季活动。',
                spreadTheme: '「全家の深夜食堂：熬夜学霸篇」\n创意描述：全家便利商店与日本知名漫画IP《深夜食堂》合作，推出「全家の深夜食堂：熬夜学霸篇」特别企划。我们将《深夜食堂》温馨治愈的风格与中国学生的熬夜学习生活相结合，创造出一系列温暖人心的漫画短篇和动画。',
                coreIdea: '主要内容包括： 漫画连载：邀请《深夜食堂》原作者安倍夜郎创作以中国大学生为主角的特别篇，每周在全家APP上更新一话，讲述学生们在全家便利商店找到温暖和力量的故事。 交互式动画：根据漫画内容制作短篇动画，让用户可以在特定情节做出选择，体验不同的故事发展。完成互动的用户可获得限定版「全家の深夜食堂」周边商品。 实体主题店：在大学周边的指定全家门市打造「深夜食堂」主题专区，不仅装潢风格模仿漫画场景，还提供漫画中出现的特色餐点，让学生们体验漫画中的温暖氛围。 通过这个IP合作，我们不仅能吸引《深夜食堂》的原有粉丝，还能让更多学生感受到全家便利商店如同漫画中的「深夜食堂」一般，是他们熬夜学习时温暖可靠的陪伴。'
            },
            example4: {
                title: '范例二',
                brief: 'X-TRAIL新产品推广活动 活动内容：X-TRAIL针对已婚且有小孩的40-54岁男性，喜爱户外活动如自行车、路跑和露营的消费群体，推出新车型，强调其宽敞舒适的车内空间，适合全家出游。在小红书和朋友圈上分享户外旅行规划和家庭回忆的故事，鼓励用户分享他们的家庭旅行照片。目标明确：精准锁定喜欢户外活动且经常载家人出游的中年男性，确保产品设计符合他们的生活方式和需求。平台互动：利用社交媒体的故事功能，增加用户参与度和品牌情感连结。实用性强：突出产品的空间舒适性和多功能性，提升购车意愿。竞争品牌：Toyota RAV4、Honda CR-V 的家庭户外活动推广活动。',
                spreadTheme: '“开启美好，X-Trail与你同行”\n' +
                    '传播主题创意说明\n' +
                    '本次传播主题“开启美好，X-Trail与你同行”源自于目标人群对于家庭时间的重视及对于质量生活的追求。结合Nissan X-Trail的产品特性，如宽敞的空间和安全技术，创造一个让消费者能感受到与家人共度时光的重要性和美好的独特观点。\n',
                coreIdea: 'Nissan X-Trail推出创新的在线到线下整合营销活动“X-Trail家庭探险计划”，旨在提升家庭之间的互动与产品体验。此活动包含在微信小程序上建立一个交互式的网站平台，家庭成员可以在此规划他们的旅行路线，并根据旅行地点获得定制的安全驾驶指南和旅游小贴士。'
            },
            example5: {
                title: '范例三',
                brief: '中国肯德基在 2024 推出四年前的人气限定款“大阪无骨脆鸡”产品系列，卖点是日式风味照烧酱汁搭配无骨脆鸡以及炒面，目标对象是喜好日本文化与日式口味的年轻消费者，以及喜爱尝鲜的美食爱好者，需要推荐中国明星艺人代言/ 并在中国的社交平台推广。竞争品牌：麦当劳、摩斯汉堡的日式限定美食推广活动。',
                spreadTheme: '「味蕾旅行，日式风情一脆难求」\n' +
                    '传播主题创意说明：\n' +
                    '这个主题利用了「味蕾旅行」这一社交热梗，符合年轻人对新奇与探索的心理需求。结合「大阪无骨脆鸡」的日式照烧风味，这个创意不仅刻画出品尝此菜品就如同进行一场味觉上的日本之旅，同时也挑战消费者去寻找与品尝这种独特的风味。\n',
                coreIdea: '肯德基中国联手中国最热门的音乐平台QQ音乐，推出「味蕾音乐节」。透过QQ音乐平台推出特别策划的「大阪无骨脆鸡音乐单曲」，每首歌曲都与一款特定口味的无骨脆鸡相关联，让消费者在品尝美食的同时，享受定制的音乐体验。此外，肯德基将在QQ音乐上举办在线音乐节，邀请中国本土歌手演出，并在活动期间内提供独家优惠券，让用户点购大阪无骨脆鸡。用户可以通过参与在线互动，如分享歌单、发表美食评论等，赢取独家限量周边商品。'
            }
        },
        Workspace: {
            copyright: '艾思智创出品',
            menu: {
                team: '团队',
                usage: '用量情况',
                settings: '设置',
                exit: '退出登录'
            },
            modal: {
                update: {
                    title: '修改brief名称',
                    placeholder: '请输入brief名称'
                },
                delete: {
                    message1: '此操作将永久删除brief，',
                    message2: '是否继续？'
                },
                settings: {
                    title: '设置',
                    tab1: {
                        title: '通用',
                        label1: {
                            word1: '用',
                            word2: '户',
                            word3: '名:',
                        },
                        label2: {
                            word1: '邮',
                            word2: '箱:',
                        },
                        label3: {
                            word1: '语',
                            word2: '言',
                            word3: '环',
                            word4: '境:',
                        },
                        select: {
                            option1: '简体中文',
                            option2: '繁體中文',
                        },
                        label4: {
                            word1: '团',
                            word2: '队',
                            word3: '成',
                            word4: '员:',
                        },
                        label5: '删除所有聊天',
                        button5: '删除全部',
                        label6: '修改密码',
                        button6: '点击修改'
                    },
                    tab2: {
                        title: '搜索偏好',
                        label1: {
                            word1: '地',
                            word2: '区',
                        },
                        label2: {
                            word1: '语',
                            word2: '言',
                        },
                        placeholder1: '请选择地区',
                        placeholder2: '请选择语言'
                    },
                    changePassword: {
                        step1: {
                            button1: "通过手机修改",
                            button2: "通过邮箱修改",
                            notice: "请联系管理员设置手机号或邮箱后再修改密码"
                        },
                        step2: {
                            notice1: "为了您的账号安全，需要验证您的手机",
                            notice2: "为了您的账号安全，需要验证您的邮箱",
                            label1: "验证码",
                            placeholder1: "请输入验证码",
                            button1: "获取验证码",
                            button2: "重新发送"
                        },
                        step3: {
                            label1: "新密码",
                            placeholder1: "请输入新密码",
                            label2: "确认密码",
                            placeholder2: "请再次输入新密码",
                        },
                        buttons: {
                            ok: "确认",
                            cancel: "取消"
                        }
                    }
                }
            }
        },
        MoreIdea: {
            button: {
                cancel: '取消',
                ok: '完成',
                help: '使用指南',
                finishEdit: '完成替换',
                cancelEdit: '取消替换',
                moreIdea: '给我更多',
                replace: '替换原文',
                export: '下载为',
                wordCn: 'Word（中文）',
                wordEn: 'Word（英文）',
                freeMind: 'FreeMind',
                markdown: 'Markdown'
            },
            message: {
                error1: "请先选择要脑暴的方案内容",
                error2: '至少选一个选项',
                error3: '至多选一个选项'
            },
            tour: {
                pre: '上一步',
                next: '下一步',
                finish: '我理解了'
            },
            tour1: {
                step1: '在这里选择想替换的内容',
                step2: '点击获取更多创意（至多5次）',
                step3: '在这里查看更多创意',
                step4: '点击翻页',
                step5: '选择创意并替换至原文对应的地方'
            },
            tour2: {
                step1: '艾迪已经把原本的主推荐创意帮您放入待脑暴区了，可以点击“开始脑暴”来生成更多的创意。当看到您满意的创意后，可以勾选出来，并点击“替换原文”，然后它就会替换左侧原方案中的对应部分'
            }
        },
        FullResearch: {
            placeholder1: '请告诉艾迪这次情报调研的内容。如，xxx产品将于下个月上市，请帮我做一下关于它和竞品，行业和市场的调研简报'
        }
    },
    components: {
        PageHeader: {
            userCenter:'会员中心'
        },
        WaterfallPage: {
            button: {
                example: '参考范例',
                editBrief: '修改Brief',
                generatePlan: '生成创意',
                finishEdit: '完成修改',
                editPlan: '更多创意',
                fullPlan: '完成方案',
                export: '下载为',
                wordCn: 'Word（中文）',
                wordEn: 'Word（英文）',
                freeMind: 'FreeMind',
                markdown: 'Markdown'
            },
            mode: {
                crazy: '--疯狂创意模式'
            },
            input: {
                title: '本次ADGo的任务：'
            },
            brief: {
                title: 'Brief文件',
                viewSource: '查看数据来源',
                anchor: {
                    title: 'Brief'
                }
            },
            plan: {
                anchor: {
                    title1: '头脑风暴',
                    title2: '完整方案'
                }
            },
            dialog: {
                content1: '请确认你的brief',
                content2: '需要开始生成方案吗？',
                ok: '好',
                cancel: '再想想'
            },
            dialog3: {
                content1: '请确认你的brief',
                content2: '需要开始生成方案吗？',
            },
            complete: {
                message1: '艾迪已努力完成工作了！请您仔细阅读。'
            },
            waiting: {
                message1: '艾迪正在思考中，您请先喝杯咖啡，稍等我{waitingTime}分钟',
                message2: '（前方正在排队，请稍后）'
            },
            waiting2: {
                message1: '艾迪正在思考中，您请先喝杯咖啡，稍等我{waitingTime}分钟',
                message2: '（前方正在排队，请稍后）'
            },
            error1: {
                message1: '很抱歉，艾迪不知道把方案放哪去了',
                message2: '（系统报错，将为你重新生成）',
                redoTask: '重新生成'
            },
            error2: {
                message1: '很抱歉，艾迪生病回家了，请紧急联系他的老板“老张”。',
                message2: '（系统报错，请发送信息：'
            },
            error3: {
                title:'功能限制提示',
                message1: '很抱歉，艾迪在历史数据中迷路了，您可以重新发起一次脑暴，让他重新找到方向。',
            },
            dialog2: {
                content1: '你确认要用当前主推荐创意来生成完整方案么？',
                content2: '点击“开始脑暴”按钮获得更多创意推荐',
                button1: '开始脑暴',
                button2: '传播计划',
                button3: 'KOL计划'
            },
            autoSave: '自动保存成功'
        },
        UsagePage: {
            ToolBar: {
                title: "用量情况",
                self: "本人",
                others: "其他成员"
            },
            chart: {
                title1: '月消耗',
                title2: '总消耗'
            },
            circle: {
                title: '消耗/剩余'
            },
            BalanceTable: {
                title1: '获取时间',
                title2: '状态',
                title3: '余额',
                title4: '过期时间',
                status1: '过期',
                status2: '可用'
            },
            TaskTable: {
                title: '实际使用情况',
                notice: {
                    content1: '代币消耗说明',
                    content2: '方案',
                    content3: '翻译',
                },
                title1: '功能',
                title2: '类型',
                title3: '次数'
            }
        }
    }
}